import { minDesktopCss } from "@common/styles/media"
import {
	BodyMediumTextCss,
	LabelRegularTextCss,
} from "@common/styles/typographies"
import styled, { css } from "styled-components"

export const PaddedWrapper = styled.div<{ $padding?: string }>`
	padding: ${({ $padding }) => $padding ?? 0};
`

export const AssetButtonWrapper = styled.div<{ $fullWidth: boolean }>`
	width: 100%;
	${minDesktopCss(css<{ $fullWidth: boolean }>`
		min-width: 158px;

		max-width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "158px")};
		button {
			width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "158px")};
			padding: 8px;
			height: 40px;
		}
	`)}

	button {
		padding: 12px;
		height: 44px;
	}
`

export const PrimaryText = styled.span`
	color: ${({ theme }) => theme.colors.text.primary};
	${LabelRegularTextCss}
	${minDesktopCss(css`
		${BodyMediumTextCss}
	`)}
`
export const StyledImage = styled.img``
